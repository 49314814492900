import config from './config';

export function request (url, {method = 'GET', data, headers = {'Content-Type': 'application/json'}} = {}) {
	let request = {
		method,
		headers
	};
	if (data) {
		request.body = JSON.stringify(data);
	}
	return fetch(config.api + url, request).then(res => {
		const contentType = res.headers.get('content-type');
		return contentType && contentType.includes('application/json') ? res.json() : {}
	}).then(res => {
		if (res.error) {
			throw res.error;
		} else {
			return res;
		}
	});
}