<script>
	import Advert from "@/advert";
	export default {
		name: 'App',
		components: {Advert},
		data() {
			const now =  Date.now() / 1000;
			const timeFrame = 12 * 60 * 60;
			const buffetId = location.hash.replace('#', '');			

			return {
				netErrorCounter: 0,
				filter: {
					where: {
						buffetId,
						status: 'orderReady',
						date: {gt: now - timeFrame},
						//serial: { exists: true }
					},
					order: 'updated ASC',
					limit: 16,
					skip: 0,
				},
				ordersFirstPart: [],
				ordersSecondPart: [],
				totalOrderCount: 0,
				buffet: {},

				advertVisible: false,
				adverts: [],
				actualAdvertIndex: 0
			}
		},
		computed: {
			orders() {
				return this.ordersFirstPart.concat(this.ordersSecondPart);
			},
			actualAdvert() {
				return this.adverts?.[this.actualAdvertIndex] || {};
			},
			actualIsSideAdvert() {
				return this.isSideAdvert(this.actualAdvert);
			}
		},
		created() {
			this.keepBuffetUptoDate();
			this.keepOrdersUpToDate();
		},
		methods: {
			keepOrdersUpToDate: async function () {
				await this.refreshOrdersCount()
				.then(() => this.refreshOrders())				
				.then(async () => {
					setTimeout(this.keepOrdersUpToDate,5000);
				})
				.catch(err => {
					console.error(err);
					this.netErrorCounter ++;
					setTimeout(this.keepOrdersUpToDate, 2000 * this.netErrorCounter);
				});
			},
			refreshOrders() {
				const limitAtQueryStart = this.filter.limit;
				return this.request('foodOrderIds?filter=' + JSON.stringify(this.filter))
					.then(async res => {
						if(limitAtQueryStart !== this.filter.limit) {
							// filter changed since this query started --> SKIP
							return;
						}
						if(this.filter.skip === 1 || res.length === this.filter.limit) {
							this.ordersSecondPart = [];
						}
						this.ordersFirstPart = res
						// if the limit < totalOrderCount -> we need to paginate
						if (this.filter.limit < this.totalOrderCount) {
							// if totalOrderCount - "limit" smaller then "skip" ->
							// start filling ordersSecondPart to restart the cycle
							if (this.filter.skip > this.totalOrderCount - this.filter.limit) {
								let secondQueryFilter = JSON.parse(JSON.stringify(this.filter));
								secondQueryFilter.skip = 0;
								secondQueryFilter.limit = this.filter.limit - (this.totalOrderCount - this.filter.skip);
								await this.request('foodOrderIds?filter=' + JSON.stringify(secondQueryFilter))
										.then(res => this.ordersSecondPart = res);
							}
							if (this.filter.skip < this.totalOrderCount) {
								// paginate one by one
								this.filter.skip++;
							} else {
								this.filter.skip = 1;
							}
							if(res.length <= this.filter.limit) {
								//this.filter.skip = 0;
							}
						} else {
							// reset pagination, when order count decreased
							this.filter.skip = 0;
							this.filter.limit = this.actualIsSideAdvert ? 12 : 16;
						}
					});
			},
			refreshOrdersCount() {
				return this.request('foodOrders/count?where=' + JSON.stringify(this.filter.where))
					.then(res => this.totalOrderCount = res.count);
			},
			keepBuffetUptoDate() {
				this.refreshBuffet(this.filter.where.buffetId)
					.then(() => {						
						if(this.buffet.advertEnabled) {
							return this.refreshAdverts()
									.then(this.startScreenTime)
									.catch(console.error)
						} else {
							setTimeout(() => {
								this.keepBuffetUptoDate();
							}, 5000);
						}
					})
					.catch(err => {
						console.error(err);
						this.netErrorCounter ++;
						setTimeout(this.keepBuffetUptoDate, 2000 * this.netErrorCounter);
					});
			},
			refreshBuffet(id) {
				return this.request('buffets/' + id)
					.then(res => this.buffet = res);
			},
			refreshAdverts() {
				return this.request('adverts?filter={"order":"position ASC"}')
					.then(res => this.adverts = res)
			},
			startScreenTime() {
				this.changeLimitAndUpdateOrders(this.actualIsSideAdvert ? 12 : 16);
				this.$set(this.actualAdvert, 'visible', true);
				setTimeout(() => {
					this.startOffTime();
				}, this.actualAdvert.screenTime * 1000);
			},
			startOffTime() {
				this.$set(this.actualAdvert, 'visible', false);

				// if the w8 time is 0 and the next advert is not side advert
				if(this.actualAdvert.waitTime > 0 || !this.isSideAdvert(this.getNextAdvert())) {
					this.changeLimitAndUpdateOrders(16);
				}

				setTimeout(() => {
					this.refreshBuffet(this.filter.where.buffetId)
						.then(() => {
							if (this.buffet.advertEnabled) {
								this.refreshAdverts()
									.then(this.paginateAdvert)
									.then(this.startScreenTime)
									.catch(console.error)
							} else {
								this.keepBuffetUptoDate()
							}
						});
				}, this.actualAdvert.waitTime * 1000)
			},
			changeLimitAndUpdateOrders: async function(limit) {
				if(this.filter.limit === limit) {
					// limit don't changed -> skip
					return;
				}
				this.filter.limit = limit;
				await this.refreshOrdersCount();
				this.refreshOrders('changeLimitAndUpdateOrders');
			},
			isSideAdvert(advert) {
				return advert.type === 'left-side' || advert.type === 'right-side';
			},
			paginateAdvert() {
				this.actualAdvertIndex = this.getNextAdvertIndex(this.actualAdvertIndex);
			},
			getNextAdvertIndex(actualIndex) {
				actualIndex++;
				if (!this.adverts[actualIndex]) {
					actualIndex = 0;
				}
				return actualIndex;
			},
			getNextAdvert() {
				return this.adverts[this.getNextAdvertIndex(this.actualAdvertIndex)];
			}
		}
	}
</script>
<template>
	<div id="app">
		<header>
			<h1>
				Átvehető rendelések | {{buffet.title}}
			</h1>
			<img class="logo" src="./assets/mvm-logo.png"/>
		</header>
		<main>
			<advert :value="actualAdvert" position="full-screen"/>

			<div class="content">
				<advert :value="actualAdvert" position="left-side"/>

				<div class="center-container">
					<transition-group
							name="bounce"
							v-if="orders && orders.length > 0"
							class="orders"
							:class="{'has-side-banner': actualIsSideAdvert && actualAdvert.visible}"
					>
						<div
								v-for="order in orders"
								v-bind:key="order.serial"
								class="order"
						>
							<label>
								<img src="./assets/check.svg"/>
								<span class="grey-font">{{order.serial.slice(0,3)}}</span>{{order.serial.slice(3)}}
							</label>
						</div>
					</transition-group>
					<advert :value="actualAdvert" position="bottom"/>
					<div class="placeholder">
						<p>Rendelj az MVM Dome büféiből az MVM Dome weboldalán
						<br/>mvm-dome.hu keresztül!</p>
					</div>
				</div>
				<advert :value="actualAdvert" position="right-side"/>
			</div>

			<div v-if="advertVisible && actualAdvert.type === 'fullscreen'">
				<img :src="getImage(actualAdvert.image_key)"/>
			</div>
		</main>
	</div>
</template>
<style scoped>
	.bounce-enter-active {
		animation: bounce-in 1s;
	}
	@keyframes bounce-in {
		0% {
			transform: scale(0);
		}
		30% {
			transform: scale(1.3);
		}
		60% {
			transform: scale(.85);
		}
		100% {
			transform: scale(1);
		}
	}
	.grey-font {
		color: #707070;
	}
	#app {
		height: 100vh;
		width: 100vw;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}
	header {
		background: #14202a;
		color: white;
		padding: 25px 35px;
		display: flex;
	}
	header h1 {
		flex: 1;
		margin: auto 0;
		font-weight: bold;
		font-size: 46px;
		display: inline-block;
		color: #ffb000;
	}
	header img {
		height: 70px;
	}
	main {
		font-size: 80px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.content {
		display: flex;
		height: 100%;
	}
	.center-container {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
	}
	.orders {
		width: 100%;
		overflow: hidden;
		margin: auto;
		padding-top: 40px;
	}
	.orders .order {
		width: 25%;
		padding: 22px 46px 8px;
		box-sizing: border-box;
		display: inline-block;
	}
	.orders.has-side-banner .order {
		width: 33.3%;
	}
	.orders .order label {
		text-align: center;
		display: flex;
		border-bottom: 1px solid #CECECE;
		padding-bottom: 40px;
	}
	.orders .order label img {
		border-radius: 50%;
		background-color: #D4A514;
		height: 46px;
		width: 46px;
		margin: auto 24px auto 0;
		padding: 11px 9px;
		box-sizing: border-box;
	}
	.placeholder {
		text-align: center;
		padding: 0 120px;
		margin: auto;
		font-size: 44px;
		color: #707070;
		box-sizing: border-box;
	}
	.logo {
		padding-right: 45px;
	}
</style>
