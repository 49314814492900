<script>
export default {
	props: {
		value: {
			type: Object
		},
		position: {
			type: String
		}
	},
	methods: {
		getImage(image_key) {
			return 'https://d3m3kyuc92i4ll.cloudfront.net/' + image_key + '_hd.png';
		}
	}
}
</script>
<template>
	<transition name="fade">
		<img
				class="advert"
				:class="position"
				v-if="value.visible && value.type === position"
				:src="getImage(value.image_key)"/>
	</transition>
</template>
<style scoped>
.advert,
.fade-enter-active,
.fade-leave-active {
	transition: all .3s ease;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
.advert.full-screen {
	position: absolute;
	width: 100%;
	z-index: 999;
}
.advert.left-side {
	height: 960px;
}
.advert.right-side {
	height: 960px;
}
.advert.bottom {
	position: absolute;
	bottom: 0;
	width: 100%;
	z-index: 999;
}
</style>
