import Vue from 'vue'
import App from './App.vue'
import {request} from "./http";

Vue.config.productionTip = false;

Vue.mixin({
	methods: {
		request
	}
});

new Vue({
	render: h => h(App),
}).$mount('#app')
